export const figmentRoutes = {
  top: () => `/figment/fitness-tenant`,
  // figment
  figmentStaffList: () => `/figment/figment-staff`,
  figmentStaffNew: () => `/figment/figment-staff-new`,
  figmentFitnessUserList: () => `/figment/fitness-user`,
  figmentFitnessUserDetail: ({ fitnessUserID }: { fitnessUserID: string }) =>
    `/figment/fitness-user/${fitnessUserID}`,

  // fitness
  fitnessTenantList: () => `/figment/fitness-tenant`,
  fitnessTenantNew: () => `/figment/fitness-tenant-new`,
  fitnessTenantDetail: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}`, // 現状は実質fitnessStaffList
  fitnessStaffNew: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/fitness-staff-new`,
  fitnessUserList: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/fitness-user`,
  fitnessMedicalTenantList: ({
    fitnessTenantID,
  }: {
    fitnessTenantID: string;
  }) => `/figment/fitness-tenant/${fitnessTenantID}/medical-tenant`,
  fitnessMedicalTenantNew: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/medical-tenant-new`,
  fitnessUserDetail: ({
    fitnessTenantID,
    fitnessUserID,
  }: {
    fitnessTenantID: string;
    fitnessUserID: string;
  }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/fitness-user/${fitnessUserID}`,

  // medical
  medicalTenantList: () => `/figment/medical-tenant`,
  medicalTenantNew: () => `/figment/medical-tenant-new`,
  medicalTenantDetail: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/figment/medical-tenant/${medicalTenantID}`, // 現状は実質medicalStaffList
  medicalStaffNew: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/figment/medical-tenant/${medicalTenantID}/medical-staff-new`,
  medicalFitnessUserList: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/figment/medical-tenant/${medicalTenantID}/fitness-user`,
  medicalFitnessUserDetail: ({
    medicalTenantID,
    fitnessUserID,
  }: {
    medicalTenantID: string;
    fitnessUserID: string;
  }) =>
    `/figment/medical-tenant/${medicalTenantID}/fitness-user/${fitnessUserID}`,

  // user
  userInitialPassword: () => `/user/initial-password`,
  // userResetPassword: () => `/user/reset-password`,

  // auth
  authLogin: () => `/auth/login`,
  authConfirm: () => `/auth/confirm`,
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
  authSendPasswordResetEmailSuccess: () =>
    `/auth/send-reset-password-email-success`,
};

export const figmentActions = {
  // figment
  deleteFigmentStaff: () => `/figment/figment-staff-delete`,
  reinviteFigmentStaff: () => `/figment/figment-staff-reinvite`,
  figmentSearchMedicalTenants: () => {
    return `/figment/search-medical-tenants`;
  },
  figmentSearchRelatedMedicalTenants: () => {
    return `/figment/search-related-medical-tenants`;
  },

  // fitness
  deleteFitnessStaff: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/fitness-staff-delete`,
  reinviteFitnessStaff: ({ fitnessTenantID }: { fitnessTenantID: string }) =>
    `/figment/fitness-tenant/${fitnessTenantID}/fitness-staff-reinvite`,
  deleteFitnessMedicalTenant: ({
    fitnessTenantID,
  }: {
    fitnessTenantID: string;
  }) => `/figment/fitness-tenant/${fitnessTenantID}/medical-tenant-delete`,
  fitnessSearchRelatedMedicalTenants: ({
    fitnessTenantID,
  }: {
    fitnessTenantID: string;
  }) => {
    return `/figment/fitness-tenant/${fitnessTenantID}/search-related-medical-tenants`;
  },

  // medical
  deleteMedicalStaff: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/figment/medical-tenant/${medicalTenantID}/medical-staff-delete`,
  reinviteMedicalStaff: ({ medicalTenantID }: { medicalTenantID: string }) =>
    `/figment/medical-tenant/${medicalTenantID}/medical-staff-reinvite`,

  // user
  userSendPasswordResetEmail: () => `/user/send-reset-password-email`,
  userLogout: () => `/user/logout`,

  // auth
  authSendPasswordResetEmail: () => `/auth/send-reset-password-email`,
};
